import { DEFAULT_COVERAGES } from '@/constants/locale.const';
import { RevisionType } from '@/enums/rating.enum';

export const PAGE_SIZE_BUILD = 50;

export const UPLOAD_STATUS_MESSAGE = {
  UPLOAD_STOPPED: 'UPLOAD_STOPPED',
  UPLOAD_FAILED: 'UPLOAD_FAILED',
  REVIEWING: 'REVIEWING',
  BUILD_CREATION_FAILED: 'BUILD_CREATION_FAILED',
  SECURITY_INSPECTION_FAILED: 'SECURITY_INSPECTION_FAILED',
  SECURITY_INSPECTION_REVIEWING: 'SECURITY_INSPECTION_REVIEWING',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
  STOPPED: 'STOPPED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAIL: 'FAIL',
  REQUEST: 'REQUEST',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  READY: 'READY',
  START: 'START'
} as const;

export const ERROR_CODE_UPLOAD_FAIL_3002 = '3002';
export const ERROR_CODE_SECURITY_INSPECTION_FAIL_10106 = '10106';
export const ERROR_CODE_SECURITY_INSPECTION_FAIL_10107 = '10107';

export const ARRAY_ERROR_CODE_SECURITY_INSPECTION_FAIL = [
  ERROR_CODE_SECURITY_INSPECTION_FAIL_10106,
  ERROR_CODE_SECURITY_INSPECTION_FAIL_10107
];

export const VARIABLE_CODE_LANG: { [key: string]: string } = {
  3: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg3',
  998: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  999: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1000: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1001: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1002: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1003: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1004: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1005: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1100: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1101: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1102: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  1103: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2000: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2001: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2002: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2100: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2101: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2102: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2200: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2201: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2202: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2203: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2300: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2301: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2302: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2303: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2400: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2401: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2402: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2403: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2500: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2501: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2600: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2601: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2602: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2603: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2700: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2701: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2702: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2800: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2801: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2802: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2803: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2900: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  2901: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3000: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3001: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3002: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3003: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3100: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3101: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3102: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3103: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3200: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3201: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3202: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3203: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3300: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3301: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3302: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3303: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3400: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3401: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3402: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3500: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3501: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3502: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3503: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3600: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',
  3601: 'studio.prj_prod.this_prod.product_data.build.home_upload_status_failed_pop_msg2',

  10000: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  10001: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  10002: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  10003: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  10100: 'studio.build_mgmt.vaccine_task_error.file_scan_error_msg',
  10101: 'studio.build_mgmt.vaccine_task_error.file_scan_error_msg',
  10102: 'studio.build_mgmt.vaccine_task_error.file_scan_error_msg',
  10103: 'studio.build_mgmt.vaccine_task_error.file_scan_error_msg',
  10104: 'studio.build_mgmt.vaccine_task_error.file_scan_error_msg',
  10105: 'studio.build_mgmt.vaccine_task_error.file_scan_error_msg',
  10106: 'studio.build_mgmt.vaccine_task_error.malicious_code_error_msg',
  10107: 'studio.build_mgmt.vaccine_task_error.vaccine_task_impossible_error_msg',
  20000: 'studio.build_mgmt.make_build_task_error.build_check_error_msg',
  20001: 'studio.build_mgmt.make_build_task_error.build_check_error_msg',
  20002: 'studio.build_mgmt.make_build_task_error.build_check_error_msg',
  20003: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20100: 'studio.build_mgmt.make_build_task_error.build_check_error_msg',
  20101: 'studio.build_mgmt.make_build_task_error.build_check_error_msg',
  20102: 'studio.build_mgmt.make_build_task_error.build_check_error_msg',
  20103: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20200: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  20201: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  20202: 'studio.build_mgmt.vaccine_task_error.file_download_error_msg',
  20203: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20300: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20301: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20302: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20303: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20304: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20305: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20306: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20400: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20401: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20402: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20403: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20404: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20405: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20406: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20407: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20408: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20409: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20410: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20411: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20412: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20413: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20500: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20501: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20502: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20503: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20600: 'studio.build_mgmt.make_build_task_error.make_build_error_msg',
  20601: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20700: 'studio.build_mgmt.make_build_task_error.drm_apply_error_msg',
  20701: 'studio.build_mgmt.make_build_task_error.drm_apply_error_msg',
  20702: 'studio.build_mgmt.make_build_task_error.drm_apply_error_msg',
  20703: 'studio.build_mgmt.make_build_task_error.drm_apply_error_msg',
  20704: 'studio.build_mgmt.make_build_task_error.drm_apply_error_msg',
  20705: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  20800: 'studio.build_mgmt.make_build_task_error.drm_apply_error_msg',
  20801: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  30000: 'studio.build_mgmt.drm_maker_task_error.process_prep_error_msg',
  30001: 'studio.build_mgmt.drm_maker_task_error.process_prep_error_msg',
  30002: 'studio.build_mgmt.drm_maker_task_error.process_prep_error_msg',
  30100: 'studio.build_mgmt.drm_maker_task_error.prod_info_check_error_msg',
  30101: 'studio.build_mgmt.drm_maker_task_error.prod_info_check_error_msg',
  30102: 'studio.build_mgmt.drm_maker_task_error.prod_info_check_error_msg',
  30200: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30201: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30202: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30300: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30301: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30302: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30303: 'studio.build_mgmt.drm_maker_task_error.build_info_check_error_msg',
  30400: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30401: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30402: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30403: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30404: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30405: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30406: 'studio.build_mgmt.drm_maker_task_error.file_check_error_msg',
  30500: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30501: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30502: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30503: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30600: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30601: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30602: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30603: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30604: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30605: 'studio.build_mgmt.drm_maker_task_error.storage_check_error_msg',
  30700: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30701: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30702: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30703: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30704: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30705: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30800: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30801: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30802: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30803: 'studio.build_mgmt.drm_maker_task_error.drm_apply_error_msg',
  30900: 'studio.build_mgmt.drm_maker_task_error.build_reconfig_error_msg',
  30901: 'studio.build_mgmt.drm_maker_task_error.build_reconfig_error_msg',
  30902: 'studio.build_mgmt.drm_maker_task_error.build_reconfig_error_msg',
  31000: 'studio.build_mgmt.drm_maker_task_error.build_reconfig_error_msg',
  31001: 'studio.build_mgmt.drm_maker_task_error.build_reconfig_error_msg',
  31002: 'studio.build_mgmt.drm_maker_task_error.build_reconfig_error_msg'
};

export const VARIABLE_CODE_LANG_BUILD: { [key: string]: string } = {
  998: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  999: 'studio.build_mgmt.build_pipeline_common_error.unknown_error_msg',
  1000: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1001: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1002: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1003: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1004: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1005: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1006: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg',
  1007: 'studio.build_mgmt.build_pipeline_common_error.process_prep_error_msg'
};

export const ERROR_CODE_NEEDING_EMAIL_SUPPORT = [
  '998',
  '999',
  '1000',
  '1001',
  '1002',
  '1003',
  '1004',
  '1005',
  '1006',
  '1007',
  '10000',
  '10001',
  '10002',
  '10003',
  '10100',
  '10101',
  '10102',
  '10103',
  '10104',
  '10105',
  '20000',
  '20001',
  '20002',
  '20003',
  '20100',
  '20101',
  '20102',
  '20103',
  '20200',
  '20201',
  '20202',
  '20203',
  '20300',
  '20301',
  '20302',
  '20303',
  '20304',
  '20305',
  '20306',
  '20400',
  '20401',
  '20402',
  '20403',
  '20404',
  '20405',
  '20406',
  '20407',
  '20408',
  '20409',
  '20410',
  '20411',
  '20412',
  '20413',
  '20500',
  '20501',
  '20502',
  '20503',
  '20600',
  '20601',
  '20700',
  '20701',
  '20702',
  '20703',
  '20704',
  '20705',
  '20800',
  '20801'
];

export const NUMBER_VERIFICATION_DEFAULT = '120';

export const PAGE_SIZE_MAKER = 20;
export const PAGE_SIZE_INTEGRITY = 40;

export const SORT_BY_MAKER = {
  FILE_ID: 'FILE_ID',
  RELATIVE_PATH: 'RELATIVE_PATH',
  APPLICATION_STATUS: 'APPLICATION_STATUS'
};

export const APPLICATION_STATUS = {
  REQUESTED: 'REQUESTED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  REQUESTED_DELETE: 'REQUESTED_DELETE',
  NULL: 'NULL'
} as const;

export const INTEGRITY_TYPE = {
  EXIST_HASH: 'EXIST_HASH',
  EXIST: 'EXIST'
};

export const VALUE_INTEGRITY_TYPE = {
  EXIST_HASH: 1,
  EXIST: 2,
  NONE: 0
};

export const SORT_BY_INTEGRITY = {
  FILE_ID: 'FILE_ID',
  RELATIVE_PATH: 'RELATIVE_PATH',
  INTEGRITY_TYPE: 'INTEGRITY_TYPE'
};

export const INTEGRITY_TYPE_OPTIONS = {
  [VALUE_INTEGRITY_TYPE.EXIST_HASH]:
    'studio.prj_prod.this_prod.product_data.build.details_integrity_check_type_exist_hash_msg',
  [VALUE_INTEGRITY_TYPE.EXIST]:
    'studio.prj_prod.this_prod.product_data.build.details_integrity_check_type_exist_msg'
};

export const BUILD_KEY_NAME_COLUMN = {
  BUILD_ID: 'buildId',
  UPLOAD_STATUS: 'uploadStatus',
  SIZE: 'size',
  DATE: 'date',
  RELEASE_STATUS: 'releaseStatus',
  ACTION: 'actions',
  INTEGRITY: 'integrity'
};

export const RELEASE_STATUS_BUILD = {
  NONE: 'NONE',
  VERIFY_UNDER_REVIEW: 'VERIFY_UNDER_REVIEW',
  VERIFY_REVIEW_REJECTED: 'VERIFY_REVIEW_REJECTED',
  VERIFY_REVIEW_COMPLETED: 'VERIFY_REVIEW_COMPLETED',
  RELEASE_UNDER_REVIEW: 'RELEASE_UNDER_REVIEW',
  COMING_SOON: 'COMING_SOON',
  APPLY_LIVE: 'APPLY_LIVE',
  END: 'END'
};

export const APPLY_LIVE_RELEASE_TYPE = {
  SCHEDULED: 'SCHEDULED',
  IMMEDIATE: 'IMMEDIATE'
} as const;

export const BUILD_PROGRESS_MESSAGES: Record<number, string> = {
  0: 'studio.build_mgmt.step_waiting',
  1: 'studio.build_mgmt.step_build_check',
  2: 'studio.build_mgmt.step_build_check',
  3: 'studio.build_mgmt.step_build_check',
  4: 'studio.build_mgmt.step_file_download',
  5: 'studio.build_mgmt.step_make_build',
  6: 'studio.build_mgmt.step_make_build',
  7: 'studio.build_mgmt.step_make_build',
  8: 'studio.build_mgmt.step_make_build',
  9: 'studio.build_mgmt.step_drm_apply',
  10: 'studio.build_mgmt.step_drm_apply',
  11: 'studio.build_mgmt.step_waiting_for_completion',
  12: 'studio.build_mgmt.step_waiting_for_completion'
} as const;

export const SECURITY_PROGRESS_MESSAGES: Record<number, string> = {
  0: 'studio.build_mgmt.step_waiting',
  1: 'studio.build_mgmt.step_file_download',
  2: 'studio.build_mgmt.step_file_scan',
  3: 'studio.build_mgmt.step_waiting_for_completion',
  4: 'studio.build_mgmt.step_waiting_for_completion'
} as const;

export const TYPE_STATUS_DRM_SECURITY = {
  NONE: 'NONE',
  NORMAL: 'NORMAL',
  HIGH: 'HIGH'
} as const;

export const SECURITY_LEVEL_DISPLAY = {
  [TYPE_STATUS_DRM_SECURITY.HIGH]:
    'studio.prj_prod.this_prod.product_data.build.details_select_file_security_standard_high',
  [TYPE_STATUS_DRM_SECURITY.NORMAL]:
    'studio.prj_prod.this_prod.product_data.build.details_select_file_security_standard_basic'
};

export const REVISION_TYPE_TO_TRANSLATION = {
  [RevisionType.BugFix]:
    'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item1_bug_fix',
  [RevisionType.Background]:
    'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item2_background_img_vid',
  [RevisionType.Ui]:
    'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item3_ui',
  [RevisionType.Billing]:
    'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item4_billing_sys',
  [RevisionType.Scenario]:
    'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item5_scenario',
  [RevisionType.Character]:
    'studio.prj_prod.build_mgmt.build_detail.post_release_build_update.case_self_review.updated_item6_char_item'
} as const;

export type LocaleType = (typeof DEFAULT_COVERAGES)[number];

export const LOCALIZED_IMAGES = {
  CLIENT_RUN_TEST: {
    [DEFAULT_COVERAGES[4]]: new URL(
      '@/assets/images/build/client-run-test-image/client-run-test-image_ko.png',
      import.meta.url
    ).href,
    [DEFAULT_COVERAGES[0]]: new URL(
      '@/assets/images/build/client-run-test-image/client-run-test-image_en.png',
      import.meta.url
    ).href,
    [DEFAULT_COVERAGES[3]]: new URL(
      '@/assets/images/build/client-run-test-image/client-run-test-image_ja.png',
      import.meta.url
    ).href,
    [DEFAULT_COVERAGES[1]]: new URL(
      '@/assets/images/build/client-run-test-image/client-run-test-image_zh_cn.png',
      import.meta.url
    ).href,
    [DEFAULT_COVERAGES[2]]: new URL(
      '@/assets/images/build/client-run-test-image/client-run-test-image_zh_tw.png',
      import.meta.url
    ).href
  }
} as const;

export type LocalizedImageType = {
  [K in LocaleType]: string;
};

export const ERROR_TYPE = {
  BUILD: 'build',
  GENERAL: 'general'
} as const;

export type ErrorType = (typeof ERROR_TYPE)[keyof typeof ERROR_TYPE];

export const STORAGE_KEY = 'buildDetailsOldBuildBanner';

export const DATA_VERSION = {
  OLD: 1
} as const;
