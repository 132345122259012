export enum RatingType {
  RECLASSIFICATION = 'RECLASSIFICATION',
  RECLASSIFICATION_COMMITTEE = 'RECLASSIFICATION_COMMITTEE'
}

export enum RatingContentCategory {
  VIOLENT = 'VIOLENT',
  OBSCENE = 'OBSCENE',
  GAMBLING = 'GAMBLING',
  HORROR = 'HORROR',
  VILIFICATION = 'VILIFICATION',
  MEDICATION = 'MEDICATION',
  CRIME = 'CRIME'
}

export enum ChoiceType {
  Radio = '01', // 하나만 선택 가능
  Checkbox = '02' // 여러개 선택 가능
}

export enum RatingHistory {
  RATING_VERIFY_PASS = 'RATING_VERIFY_PASS',
  RATING_VERIFY_REJECT = 'RATING_VERIFY_REJECT',
  RATING_REVISION_PASS = 'RATING_REVISION_PASS',
  RATING_REVISION_REJECT = 'RATING_REVISION_REJECT',
  RATING_RECLASSIFICATION = 'RATING_RECLASSIFICATION',
  RATING_RECLASSIFICATION_COMMITTEE = 'RATING_RECLASSIFICATION_COMMITTEE',
  RATING_CANCELED = 'RATING_CANCELED',
  RATING_REJECTED = 'RATING_REJECTED',
  DIRECT_RATING_REGISTER = 'DIRECT_RATING_REGISTER',
  DIRECT_RATING_MODIFY = 'DIRECT_RATING_MODIFY',
  GRAC_RATING_REGISTER = 'GRAC_RATING_REGISTER',
  RATING_RECLASSIFICATION_ADJUSTED = 'RATING_RECLASSIFICATION_ADJUSTED',
  RATING_RECLASSIFICATION_COMMITTEE_ADJUSTED = 'RATING_RECLASSIFICATION_COMMITTEE_ADJUSTED',
  RATING_CANCEL_ADJUSTED = 'RATING_CANCEL_ADJUSTED',
  RATING_REJECT_ADJUSTED = 'RATING_REJECT_ADJUSTED',
  GLOBAL_RATING_MODIFY = 'GLOBAL_RATING_MODIFY',
  GLOBAL_RATING_REGISTER = 'GLOBAL_RATING_REGISTER',
  GLOBAL_RATING_DELETE = 'GLOBAL_RATING_DELETE',
  BUG_FIX_REVISION = 'BUG_FIX_REVISION',
  GRAC_RATING_DELETE = 'GRAC_RATING_DELETE',
  RATING_DELETE = 'RATING_DELETE',
  RATING_OBJECTION_PASS = 'RATING_OBJECTION_PASS',
  RATING_OBJECTION_REJECT = 'RATING_OBJECTION_REJECT'
}

export enum RatingHistoryAgeRating {
  ALL_AGES = 'ALL_AGES',
  ADULT_ONLY = 'ADULT_ONLY',
  AGES_15 = 'AGES_15',
  AGES_12 = 'AGES_12',
  REJECT_RATING = 'REJECT_RATING',
  NOT_APPLICABLE_TO_RATINGS = 'NOT_APPLICABLE_TO_RATINGS'
}

export enum RatingRevisionType {
  BUG_FIX = 'BUG_FIX',
  CHANGED_CONTENTS_1 = 'CHANGED_CONTENTS_1',
  CHANGED_CONTENTS_2 = 'CHANGED_CONTENTS_2',
  CHANGED_CONTENTS_3 = 'CHANGED_CONTENTS_3',
  CHANGED_CONTENTS_4 = 'CHANGED_CONTENTS_4',
  MODIFIED_BILLING_SYSTEM = 'MODIFIED_BILLING_SYSTEM',
  CHANGED_GENRE = 'CHANGED_GENRE',
  OTHERS = 'OTHERS',
  MOBILE_BINARY = 'MOBILE_BINARY'
}

export enum SurveyDialogType {
  REVISION = 0,
  OBJECTION = 1,
  REGISTER = 2
}

export enum RatingSurveyTab {
  REVISION = 0,
  OBJECTION = 1,
  SURVEY = 2,
  ATTACH = 3
}

// status: 'complete' | 'incomplete' | 'start';
export enum RatingSurveyStatus {
  COMPLETE = 0,
  INCOMPLETE = 1,
  START = 2
}

export enum RatingStatus {
  SAVE = 'SAVE',
  VERIFY_REJECTED = 'VERIFY_REJECTED',
  VERIFY_PASSED = 'VERIFY_PASSED',
  VERIFYING = 'VERIFYING',
  REJECTED = 'REJECTED',
  LINKED = 'LINKED',
  DISCARD = 'DISCARD',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NOTICED = 'NOTICED',
  ACCEPTED = 'ACCEPTED',
  ADJUSTED = 'ADJUSTED',
  MODIFICATION = 'MODIFICATION',
  CANCELED = 'CANCELED',
  EXPLAINING = 'EXPLAINING'
}

export enum RatingTypeStatus {
  RECLASSIFICATION = 'RECLASSIFICATION',
  RECLASSIFICATION_COMMITTEE = 'RECLASSIFICATION_COMMITTEE',
  RATING_CANCELED = 'RATING_CANCELED',
  RATING_REJECTED = 'RATING_REJECTED',
  GAME_REVISION = 'GAME_REVISION',
  GAME_REVISION_RECLASSIFICATION_COMMITTEE = 'GAME_REVISION_RECLASSIFICATION_COMMITTEE',
  GAME_REVISION_RECLASSIFICATION = 'GAME_REVISION_RECLASSIFICATION'
}

export enum RatingPageMode {
  DETAIL = 'DETAIL',
  EDIT = 'EDIT'
}

export const enum RatingAge {
  AllAges = '0', // 전체이용가
  Age12 = '12', // 12세이용가
  Age15 = '15', // 15세이용가
  AdultOnly = '19', // 청소년이용불가
  RejectRating = 'REJECT', // 등급거부
  Cancel = 'CANCEL' // 등급취소
}

export const enum RatingContentInfo {
  Violence = 'VIOLENCE', // 폭력성
  Sexuality = 'SEXUALITY', // 선정성
  Gambling = 'GAMBLING', // 사행성
  Fear = 'FEAR', // 공포
  Language = 'LANGUAGE', // 언어의 부적절성
  Drug = 'DRUG', // 약물
  Crime = 'CRIME' // 범죄
}

export enum RevisionType {
  Character = 'CHANGED_CONTENTS_1', // 캐릭터, 아이템 콘텐츠 변경 - Change character and item content
  Scenario = 'CHANGED_CONTENTS_2', // 퀘스트, 시나리오 콘텐츠 변경 - Quest and scenario content changes
  Background = 'CHANGED_CONTENTS_3', // 배경, 영상 콘텐츠 변경 - Change background and video content
  Ui = 'CHANGED_CONTENTS_4', // 기본 시스템, UI 변경 - Basic system, UI changes
  Billing = 'MODIFIED_BILLING_SYSTEM', // 과금체계 수정 - Modification of billing system
  Genre = 'CHANGED_GENRE', // 장르 변경 - change genre
  Etc = 'OTHERS', // 기타 - other
  BugFix = 'BUG_FIX', // 버그 수정 - bug fix

  // Fake RevisionType for change product setting, convert to OTHER when submit to server
  GameName = 'GAME_NAME',
  Developer = 'DEVELOPER'
}

export const enum RatingBoard {
  Grac = 'GRAC',
  Iarc = 'IARC',
  Esrb = 'ESRB',
  Pegi1 = 'PEGI1',
  Pegi2 = 'PEGI2',
  Usk = 'USK',
  Cero = 'CERO',
  Acb = 'ACB',
  ClassInd = 'ClassInd'
}

export enum AdditionalRatingType {
  NEW = 'NEW',
  GAME_REVISION = 'GAME_REVISION',
  GAME_REVISION_RECLASSIFICATION = 'GAME_REVISION_RECLASSIFICATION',
  GAME_REVISION_RECLASSIFICATION_COMMITTEE = 'GAME_REVISION_RECLASSIFICATION_COMMITTEE',
  RECLASSIFICATION = 'RECLASSIFICATION',
  RECLASSIFICATION_COMMITTEE = 'RECLASSIFICATION_COMMITTEE',
  OBJECTION = 'OBJECTION',
  RATING_CANCELED = 'RATING_CANCELED',
  RATING_REJECTED = 'RATING_REJECTED'
}

export enum VerifyStatus {
  NONE = 'NONE',
  REQUEST = 'REQUEST',
  START = 'START',
  PASS = 'PASS',
  REJECT = 'REJECT'
}

export enum ObjectionCode {
  EQUALS = 1,
  NOT_EQUALS = 2,
  ADULT_AND_REJECT = 3
}
